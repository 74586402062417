import React, { useEffect } from "react";

const AboutSection = () => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        } else {
          entry.target.classList.remove("show");
        }
      });
    });

    const hiddenElements = document.querySelectorAll(
      ".fade_up, .fade_down, .zoom_in, .zoom_out, .fade_right, .fade_left, .flip_left, .flip_right, .flip_up, .flip_down"
    );
    hiddenElements.forEach((el) => observer.observe(el));

    return () => {
      hiddenElements.forEach((el) => observer.unobserve(el));
    };
  }, []);
  return (
    <>
      {/* <!-- ====================================== Section About ===================================== --> */}
      <section className="About-section jos" id="about">
        <div className="heading-container">
          <h2 className="section-heading-text about-me fade_up">Hakkımda.</h2>
          <div className="line"></div>
        </div>
        <p className="section-sub-text about-sub-text zoom_in">
          Merhaba, ben <span>Berfin Görkem.</span> Yazılım geliştirme, tasarlama ve sürdürme
          konusunda 2 yılı aşkın deneyime sahip bir yazılım geliştiricisiyim.
          Aynı zamanda siber güvenlik alanında kendimi geliştirmeye devam
          ediyorum. Problem çözme yeteneğim ve en yüksek standartlara uygun
          yazılım çözümleri geliştirme tutkum ile öne çıkan, yüksek motivasyonlu
          bir bireyim. Teknoloji dünyasındaki en son gelişmeleri yakından takip
          etmekte ve projelerimi sektörün en ileri düzeyinde tutmak konusunda
          hassasiyet göstermekteyim. Ayrıca, ISO 27001, ISO 14001 ve ISO 9001
          yönetim sistemlerinde baş denetçi olarak, kalite ve güvenlik
          standartlarına yönelik uzmanlık sağlıyorum.
        </p>
        {/* <div className="about-detail-main">
          <p className="about-detail">Ad Soyad:</p>
          <p className="about-detail-info">Jessica Biogi</p>
        </div> */}
        <div className="about-detail-main">
          <p className="about-detail">Şehir</p>
          <p className="about-detail-info">Ankara</p>
        </div>
        {/* <div className="about-detail-main">
          <p className="about-detail">Phone</p>
          <p
            className="about-detail-info email"
            onClick="location.href='tel:(+1)2345678899'"
          >
            (+1) 234 567 8899
          </p>
        </div> */}
        <div className="about-detail-main">
          <p className="about-detail">Email</p>
          <p
            className="about-detail-info email"
            onClick="location.href='mailto:gorkemberfinn@gmail.com'"
          >
            gorkemberfinn@gmail.com
          </p>
        </div>
        <div className="about-detail-main">
          <p className="about-detail">Deneyim</p>
          <p className="about-detail-info">2+ yıl</p>
        </div>
        <div className="about-detail-main">
          <p className="about-detail">Freelance</p>
          <p className="about-detail-info">Mevcut</p>
        </div>
        {/* <div className="about-detail-main">
          <p className="about-detail">Skype</p>
          <p className="about-detail-info">hello.biogi</p>
        </div> */}
        {/* <div className="about-detail-main">
          <p className="about-detail">Language</p>
          <p className="about-detail-info">English</p>
        </div> */}
      </section>
      {/* <!-- ====================================== Section About End ===================================== --> */}
    </>
  );
};
export default AboutSection;
