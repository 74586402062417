import React, { useEffect, useRef } from "react";
import FigmaImg from "../assets/images/figma-img.png";
import PhotoShopImg from "../assets/images/photoshop-img.png";
import AdobeImg from "../assets/images/react.png";
import SketchImg from "../assets/images/php.png";
import InvisionImg from "../assets/images/sql.png";
import WinnerAward from "../assets/images/winner-award.png";
import WinnerAward2 from "../assets/images/winner-award2.png";
import WinnerAward3 from "../assets/images/winner-award3.png";
import WinnerAward4 from "../assets/images/winner-award4.png";

const Resume = () => {
  const colors = [
    "#BCE70C",
    "#FF759C",
    "#00CC97",
    "#FFDB59",
    "#6F39FD",
    "#FF7D61",
  ];
  const progressRef = useRef(null);
  const hasAnimated = useRef(false); // Track if the animation has already run

  useEffect(() => {
    const progressSection = progressRef.current;
    const items = progressSection.querySelectorAll(".progress-item");
    const observerOptions = { threshold: 0.1 };

    function handleIntersection(entries, observer) {
      if (entries[0].isIntersecting && !hasAnimated.current) {
        items.forEach((item, index) => {
          let num = parseInt(item.dataset.num);
          let count = 0;
          let color = colors[index % colors.length];
          let interval = setInterval(() => {
            if (count === num) {
              clearInterval(interval);
            } else {
              count++;
              item.style.background = `conic-gradient(${color} ${count}%, #80808047 0deg)`;
              item.setAttribute("data-value", `${count}%`);
              item.innerHTML = `${count}%`;
            }
          }, 15);
        });
        observer.unobserve(progressSection);
        hasAnimated.current = true; // Mark that the animation has run
      }
    }

    const observer = new IntersectionObserver(
      handleIntersection,
      observerOptions
    );
    observer.observe(progressSection);

    return () => observer.disconnect();
  }, [colors]);

  return (
    <>
      {/* <!-- ====================================== Section Education Experience ===================================== --> */}
      <section className="education-experience" id="resume">
        <div className="row">
          <div className="col-xxl-6 col-lg-6">
            <div className="heading-container">
              <h2 className="section-heading-text about-me fade_up">Eğitim.</h2>
              <div className="line"></div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Yönetim Bilişim Sistemleri</p>
                <p className="cursus university">
                  Anadolu Üniversitesi / 2024 - Devam Ediyor
                </p>
                {/* <p className="cursus">
                  Porttitor euismod at semper ut massa. Lorem varius magna
                  volutpat nunc. Et faucibus scelerisque donec augue eleifenda
                  morbi.
                </p> */}
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Bilgisayar Programcılığı</p>
                <p className="cursus university">
                  Kırıkkale Üniversitesi / 2019 - 2023
                </p>
                {/* <p className="cursus">
                  Eu nulla at mauris cursus consectetur posuere iaculis ipsum
                  neque. Morbi felis pellentesque ligula sed dictumst imperdiet
                  nunc vulputate.
                </p> */}
              </div>
            </div>
            
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Lise</p>
                <p className="cursus university">
                  Yeni Nefes Koleji / 2013 - 2017
                </p>
                {/* <p className="cursus">
                  Porttitor euismod at semper ut massa. Lorem varius magna
                  volutpat nunc. Et faucibus scelerisque donec augue eleifenda
                  morbi.
                </p> */}
              </div>
            </div>
            {/* <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Diploma in Computer</p>
                <p className="cursus university">
                  Cambridge University / 2016 - 2018
                </p>
                <p className="cursus">
                  Adipiscing sed magna tempus arcu morbi. Ipsum pellentesque
                  lorem suscipit in. Hendrerit rhoncus quis tempor urna pharetra
                  ut erat.
                </p>
              </div>
            </div> */}
          </div>
          <div className="col-xxl-6 col-lg-6">
            <div className="heading-container">
              <h2 className="section-heading-text about-me fade_up">
                Deneyim.
              </h2>
              <div className="line"></div>
            </div>

            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">
                  Kalite Yönetim Sistemleri Baş Denetçiliği
                </p>
                <p className="cursus university">
                  CyberCert / 2024 - Devam Ediyor
                </p>
                {/* <p className="cursus">
                  Aliquet at interdum pellentesque non fringilla eget orci
                  suspendisse. A iaculis augue vitae ultricies fusce sit dolor
                  gravida .
                </p> */}
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Sosyal Medya Yönetimi</p>
                {<p className="cursus university">2023 - Devam Ediyor </p>}
                {/* <p className="cursus">
                  Congue dolor gravida eu tristique netus posuere dolor.
                  Penatibus imperdiet egestas ut sit scelerisque sapien a lor
                  sjsa.
                </p> */}
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Yazılım Uzmanı</p>
                <p className="cursus university">
                  CyberCert / 2022 - Devam Ediyor
                </p>
                {/* <p className="cursus">
                  Ipsum erat duis leo lectus tellus neque dictumst. Dignissim
                  tortor quis nisl mi lectus. Massa facilisis ac eget fermentum
                  vitae. Dictum rutrum sed.
                </p> */}
              </div>
            </div>

            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Freelancer Web Geliştirici</p>
                {<p className="cursus university">2021-Devam Ediyor </p>}
                {/* <p className="cursus">
                  Congue dolor gravida eu tristique netus posuere dolor.
                  Penatibus imperdiet egestas ut sit scelerisque sapien a lor
                  sjsa.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====================================== Section Education Experience End ===================================== --> */}
      {/* <!-- ====================================== Section Coding Skill ===================================== --> */}
      <section className="coding-skill-section">
        <div className="heading-container">
          <h2 className="section-heading-text coding-skill-text fade_up">
            {/* Coding Skills. */}
          </h2>
          {/* <div className="line"></div> */}
        </div>
        <div id="progress" ref={progressRef}>
          {/* <div data-num="79" className="progress-item fade_up">
            sd
          </div>
          <div data-num="92" className="progress-item fade_up">
            sd
          </div>
          <div data-num="85" className="progress-item fade_up">
            sd
          </div>
          <div data-num="70" className="progress-item fade_up">
            sd
          </div>
          <div data-num="76" className="progress-item fade_up">
            ds
          </div>
          <div data-num="83" className="progress-item fade_up">
            ds
          </div> */}
        </div>
      </section>

      {/* <!-- ====================================== Section Coding Skill End ===================================== --> */}
      {/* <!-- ====================================== Section Design Skill ===================================== --> */}
      <section className="design-skill-section">
        <div className="heading-container">
          <h2 className="section-heading-text design-skill-text fade_up">
            Yetenekler.
          </h2>
          <div className="line"></div>
        </div>
        <div className="design-skill-sub-section">
          <div className="design-skills-img-main flip_up">
            <img src={FigmaImg} alt="figma-img" />
            <div className="skill-counter-main">
              <p>94%</p>
              <p>HTML, CSS</p>
            </div>
          </div>
          <div className="design-skills-img-main adobe-xd flip_up">
            <img src={AdobeImg} alt="adobe-xd-img" />
            <div className="skill-counter-main adobe-xd-text">
              <p>88%</p>
              <p>REACT</p>
            </div>
          </div>
          <div className="design-skills-img-main invision flip_up">
            <img src={InvisionImg} alt="invision-img" />
            <div className="skill-counter-main invision-text">
              <p>89%</p>
              <p>MySQL , PostgreSQL</p>
            </div>
          </div>
          <div className="design-skills-img-main sketch flip_up">
            <img src={SketchImg} alt="sktech-img" />
            <div className="skill-counter-main sketch-text">
              <p>85%</p>
              <p>PHP</p>
            </div>
          </div>
          
          <div className="design-skills-img-main photoshop flip_up">
            <img src={PhotoShopImg} alt="photoshop-img" />
            <div className="skill-counter-main photoshop-text">
              <p>98%</p>
              <p>PHOTOSHOP</p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====================================== Section Coding Skill End ===================================== --> */}
      {/* <!-- ====================================== Section Award ===================================== --> */}
      <section className="awards-section overflow-hidden">
        <div className="heading-container">
          <h2 className="section-heading-text coding-skill-text fade_up">
            Sertifiklar
          </h2>
          <div className="line"></div>
        </div>
        <div className="row awards-row">
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
            <div className="box-item flip_up">
              <div className="flip-box">
                <div className="flip-box-front">
                  <div className="inner">
                    <div className="years-award-img">
                      {/* <img
                        className="winner-award"
                        src={WinnerAward}
                        alt="winner-award4"
                      /> */}
                      <p className="award-yer">2022</p>
                    </div>
                    <p className="award-interior">Web Tasarımı Sertifikası</p>
                    <p className="award-winner-text">VEKTÖREL BİLİŞİM</p>
                  </div>
                </div>
                <div className="flip-box-back">
                  <div className="inner">
                    <p className="flip-back-text">
                      VEKTÖREL BİLİŞİM
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
            <div className="box-item flip_up">
              <div className="flip-box">
                <div className="flip-box-front">
                  <div className="inner">
                    <div className="years-award-img">
                      {/* <img
                        className="winner-award"
                        src={WinnerAward2}
                        alt="winner-award2"
                      /> */}
                      <p className="award-yer">2024</p>
                    </div>
                    <p className="award-interior">
                      ISO 27001 Baş Denetçi Sertifikası
                    </p>
                    <p className="award-winner-text">VERİCERT</p>
                  </div>
                </div>
                <div className="flip-box-back">
                  <div className="inner">
                    <p className="flip-back-text">
                    VERİCERT
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
            <div className="box-item flip_up">
              <div className="flip-box">
                <div className="flip-box-front">
                  <div className="inner">
                    <div className="years-award-img">
                      {/* <img
                        className="winner-award"
                        src={WinnerAward3}
                        alt="winner-award3"
                      /> */}
                      <p className="award-yer">2024</p>
                    </div>
                    <p className="award-interior">
                      ISO 14001 Baş Denetçi Sertifikası
                    </p>
                    <p className="award-winner-text">VERİCERT</p>
                  </div>
                </div>
                <div className="flip-box-back">
                  <div className="inner">
                    <p className="flip-back-text">
                    VERİCERT
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
            <div className="box-item flip_up">
              <div className="flip-box">
                <div className="flip-box-front">
                  <div className="inner">
                    <div className="years-award-img">
                      {/* <img
                        className="winner-award"
                        src={WinnerAward4}
                        alt="winner-award4"
                      /> */}
                      <p className="award-yer">2024</p>
                    </div>
                    <p className="award-interior">
                      ISO 9001 Baş Denetçi Sertifikası
                    </p>
                    <p className="award-winner-text">VERİCERT</p>
                  </div>
                </div>
                <div className="flip-box-back">
                  <div className="inner">
                    <p className="flip-back-text">
                    VERİCERT
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====================================== Section Award End ===================================== --> */}
    </>
  );
};
export default Resume;
